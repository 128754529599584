import React from "react";
import "./HomeScreen.css";

function HomeScreen() {
  return (
    <div className="home-container">
      <div className="title-container">
        <div className="title">ByteBlaze</div>
        <div className="subtitle">Software Solutions</div>
        {/* Email Button */}
        <a href="mailto:Contact@ByteBlazeSoftware.com" className="email-button">
          Contact Us
        </a>
      </div>
    </div>
  );
}

export default HomeScreen;
