import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../assets/byteblaze-logo.png";

export function MainNav() {
  return (
    <div className="navbar">
      <Link to="/" className="logo-link">
        <img src={logo} alt="ByteBlaze Logo" className="logo" />
        <span className="site-name">ByteBlaze</span>
      </Link>
      <nav className="nav-links">
        <a
          href="https://www.linkedin.com/company/byteblaze-software-solutions/"
          target="_blank"
          rel="noopener noreferrer"
          className="nav-item"
        >
          LinkedIn
        </a>
      </nav>
    </div>
  );
}
