import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import BlogScreen from "./screens/BlogScreen";
import BlogPostScreen from "./screens/BlogPostScreen"; // Import the new component
import "./App.css";
import { MainNav } from "./components/navbar";

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <MainNav />
        </header>
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/blog" element={<BlogScreen />} />
          <Route path="/blog/:id" element={<BlogPostScreen />} />{" "}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
