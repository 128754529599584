import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import BlogCard from "../components/BlogCard";
import blogPosts from "../blog/index";
import "./BlogScreen.css";

const BlogScreen = () => {
  return (
    <div className="blog-screen">
      {/*<Sidebar />*/}
      <div className="blog-feed">
        {blogPosts.map((post) => (
          <Link
            className="blog-feed-card"
            key={post.id}
            to={`/blog/${post.id}`}
          >
            <BlogCard title={post.title} summary={post.summary} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogScreen;
