import React from "react";
import BlogPost from "../components/BlogPost";

const codeExample = {
  codeSnippet: `
    /**
     * Adds two numbers.
     * @param {number} a The first number.
     * @param {number} b The second number.
     * @returns {number} Sum of a and b.
     */
    function add(a, b) {
      return a + b;
    }
  `,
  typeAssertion:  `
  let x: any = 'hello'; 
  let y: number = x as number; 
  `,
  nonNullAssertion: `
  let x: number | null = null; 
  let y: number = x!;
  `,
  tsconfig: `
  {
    "include": [
      "**/*.js"
    ],
    "compilerOptions": {
      "declaration": true,
      "emitDeclarationOnly": true,
      "allowJs": true,
      "outDir": "./types"
    }
  }
  `
};


const JSDocDTSPost = () => {
  return (
    <BlogPost>
      <h1>How we get <u>90%</u> of TypeScript with <u>10%</u> effort using <u>JSDoc</u> and <u>.d.ts</u></h1>
      <p>Published on: January 20, 2024</p>
      <p>Author: Jonathan Bonney</p>

      <section>
        <h2>Is TypeScript TypeSafe?</h2>
          <pre>
            <code>
              {codeExample.typeAssertion}
            </code>
          </pre>
          <pre>
            <code>
              {codeExample.nonNullAssertion}
            </code>
          </pre>
        <p>
          TypeScript will not catch these errors. TypeScript offers a lot of 
          useful tooling and type checking, but it is certainly <b>not type safe</b>.
          So why does TypeScript demand to be treated with such high priority that
          it infects every file in your codebase?
        </p>

        <p>
          In my opinion, it really doesn't take long before any TypeScript project becomes much more
          complex than what is warranted by the actual benefits of TypeScript. However, TypeScript
          has had a great influence on the web ecosystem. It showed us that type safety is possible
          and opened many people's eyes to other technologies like Rust. In time we may see that
          we should have stuck with true static typing from the beginning. In the meantime, I'm looking forward to Static Hermes and the future of React Native,
          so I'm sticking with JavaScript for now. 
        </p>

        <p>
          What I'd like to share is a flexible approach to documenting your JavaScript codebase
          with JSDoc and TypeScript declaration files (.d.ts). This approach allows you to enjoy
          many of the benefits of TypeScript without having to rewrite your entire codebase.

        </p>
      </section>

      <section>
        <h2>What is JSDoc?</h2>
        <p>
          JSDoc is a popular documentation syntax for JavaScript code. By
          writing comments in your JavaScript files, you can provide valuable
          information about the types of parameters, return types, and overall
          functionality of your functions and classes.
        </p>
        <pre>
          <code>
            {codeExample.codeSnippet}
          </code>
        </pre>
        
        <p>
          These annotations can be used by IDEs and other tools to provide
          autocomplete suggestions, type checking, and other useful features.
          And if all of that isn't enough, the TypeScript compiler can use
          JSDoc comments to generate TypeScript declaration files (.d.ts).
          This allows us to to support much of the amazing TypeScript tooling
          without the headache of TypeScript itself.
         </p> 
      </section>

      <section>
        <h2>TypeScript Declaration Files (.d.ts)</h2>
        <p>
          TypeScript declaration files (.d.ts) allow you to provide type
          information separately from your JavaScript code. This means you can
          gradually adopt TypeScript's type checking without rewriting existing
          code.
        </p>
        <code>
          declare function add(a: number, b: number): number;
        </code>

        <p>
          If you have a JavaScript codebase with some JSDoc annotations, here's a 
          quick guide to generate .d.ts files:
        </p>

        <ol>
          <li>
          Install TypeScript in your project by running 
          <code>npm install --save-dev typescript</code>
          in your project's root directory. 
          This will add TypeScript as a development dependency to your project.
          </li>
          <li>
          Create a TypeScript configuration file,<code>tsconfig.json</code>, in your project's 
          root directory. This file specifies the compiler options for TypeScript.
          It should look something like this:
          <pre>
            <code>
              {codeExample.tsconfig}
            </code>
          </pre>
          </li>
          <li>
          Run <code>npx tsc</code> in your project's root directory. This will
          generate .d.ts files in the specified output directory (ourDir), which is "./types" in
          the example above.
          </li>
          <li>
          In your <code>package.json</code>, you can specify the entry point for your types 
          using the types field, pointing it to the main declaration file in 
          your output directory. For example: <code>"types": "./types/index.d.ts"</code>
          You should do this if you plan on publishing your package to npm.
          </li>
        </ol>

      </section>

      <section>
        <h2>Combining JSDoc and .d.ts</h2>
        <p>
          By using JSDoc for inline documentation and .d.ts files for explicit
          type declarations, you can enjoy many of TypeScript's benefits. This
          approach is particularly useful for projects where a full migration to
          TypeScript isn't feasible or necessary.
        </p>
        <p>
          In conclusion, JSDoc and TypeScript declaration files offer a powerful
          combination for enhancing JavaScript projects with minimal overhead.
          They provide a practical path for developers looking to improve their
          codebase with type checking and better documentation.
        </p>
      </section>
    </BlogPost>
  );
};

export default JSDocDTSPost;
