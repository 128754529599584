import React from "react";
import { useParams } from "react-router-dom";
import blogPosts from "../blog/index"; // Import your blog posts index
import Sidebar from "../components/Sidebar"; // Import your Sidebar component
import "./BlogPostScreen.css"; // Import CSS for styling

const BlogPostScreen = () => {
  let { id } = useParams();
  const post = blogPosts.find((p) => p.id === id);

  if (!post) {
    return <div className="post-not-found">Post not found</div>;
  }

  const PostComponent = post.component; // The component that renders the blog post

  return (
    <div className="blog-post-screen">
    {/*<Sidebar />*/}
      <div className="blog-post-content">
        <PostComponent />
      </div>
    </div>
  );
};

export default BlogPostScreen;
