// Import the blog post components
import JSDocDTSPost from "./01-JSDOC+dts";
import Post2 from "./02-DOD";
// ... import other posts as you create them

// Blog post metadata and component mapping
const blogPosts = [
  {
    id: "01-JSDOC+dts",
    title: "How we get 90% of TypeScript with 10% of the effort",
    summary: "Using JSDoc and dts-gen to generate TypeScript definitions",
    date: "2024-01-19",
    author: "Jonathan Bonney",
    category: ["TypeScript", "JavaScript"],
    component: JSDocDTSPost,
  },
  {
    id: "02",
    title: "Data Oriented Design",
    summary: "Applications run 100-300x faster with CPU cache friendly code",
    date: "2024-02-01",
    author: "Jane Doe",
    category: ["Systems Programming", "Advanced"],
    component: Post2,
  },
];

export default blogPosts;
